import React from 'react'

import Layout from '../../components/Layout'

export default class SocialIndexPage extends React.Component {
  render() {

  return (
      <Layout>
        <h1
          className="has-text-weight-bold is-size-2 is-hidden-desktop full-width-logo primary-background"
          style={{
            color: 'white',
          }}>
          Social Media
        </h1>
        <section className="section">
          <div className="container">
            <div className="content">
            <div
                className="full-width-image-container margin-top-0"
                style={{
                  backgroundImage: `url('/img/under-construction.png')`,
                }}
              >
                <h1
                  className="has-text-weight-bold is-size-1 is-hidden-touch primary-background"
                  style={{
                    color: 'white',
                    padding: '1rem',
                  }}
                >
                  Social Media
                </h1>
              </div>
            </div>
            </div>
        </section>
      </Layout>
    )
  }
}
